import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import 'rxjs-compat/add/observable/of';
import {Observable} from 'rxjs/Observable';
import {tap} from 'rxjs/operators';
import {AuthService} from './auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(private router: Router,
                private authService: AuthService) {
    }

    /**
     * canActivate Route -> Check if is authenticated | redirect to login page if NOT
     * @param {ActivatedRouteSnapshot} next
     * @param {RouterStateSnapshot} state
     * @returns {Observable<boolean>}
     */
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authService.isAuthenticated()
            .pipe(
                tap(loggedIn => {
                    if (!loggedIn) {
                        this.router.navigate(['auth/login']);
                        return false;
                    }
                })
            );
    }
}
