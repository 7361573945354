import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { AmplifyService } from 'aws-amplify-angular';
import { Router } from '@angular/router';

@Component({
  selector: 'symb-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  public isExpanded = false;
  public signedIn: boolean;
  public user: any;
  constructor(
    public auth: AuthService,
    private amp: AmplifyService,
    public router: Router
  ) {
    this.amp.authStateChange$
      .subscribe(authState => {
        this.signedIn = authState.state === 'signedIn';
        if (!authState.user) {
          this.user = null;
        } else {
          this.user = authState.user;
          console.log(this.user)
        }
      });
  }

  ngOnInit() {
  }

  isloggedIn() {
    return this.auth.isAuthenticated()
  }

  logout() {
    this.auth.signOut();
    this.router.navigateByUrl("/auth/login")
  }

}
