import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { AmplifyService } from 'aws-amplify-angular';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { catchError, map, tap } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
    public isLoggedIn: BehaviorSubject<boolean>;

    public username: string;
    public email: string;
    public password: string;
    public verificationCode: string;

    constructor(
        public amplifyService: AmplifyService,
        private router: Router
    ) {
        this.isLoggedIn = new BehaviorSubject<boolean>(false);

        this.resetUserParameters();
    }

    /**
     * Get the current session information | If session is invalid and valid refresh token
     * (session automatically refreshes tokens)
     * @returns {Observable<any>}
     */
    public getSessionIdToken(): Observable<any> {
        return fromPromise(this.amplifyService.auth().currentSession());
    }

    /**
     * Register new account
     * @returns {Observable<any>}
     */
    public registerNew(): Observable<any> {
        return fromPromise(this.amplifyService.auth().signUp(this.username, this.password, this.email));
    }

    /**
     * Register confirm account
     * @returns {Observable<any>}
     */
    public registerConfirm(): Observable<any> {
        return fromPromise(this.amplifyService.auth().confirmSignUp(this.username, this.verificationCode));
    }

    /**
     * Login
     * @returns {Observable<any>}
     */
    public signIn(): Observable<any> {
        return fromPromise(this.amplifyService.auth().signIn(this.email, this.password))
            .pipe(
                tap(() => this.isLoggedIn.next(true))
            );
    }

    /**
     * Sign out and redirect back to login
     */
    public signOut() {
        fromPromise(this.amplifyService.auth().signOut())
            .subscribe(
                result => {
                    this.isLoggedIn.next(false);
                    this.router.navigate(['auth/login']);
                },
                error => console.log(error)
            );
    }

    /**
     * Get authenticated state
     * @returns {Observable<boolean>}
     */
    public isAuthenticated(): Observable<boolean> {
        return fromPromise(this.amplifyService.auth().currentAuthenticatedUser())
            .pipe(
                map(result => {
                    this.isLoggedIn.next(true);
                    return true;
                }),
                catchError(error => {
                    this.isLoggedIn.next(false);
                    return of(false);
                })
            );
    }

    /**
     * Forgot password Init
     * @returns {Observable<any>}
     */
    public forgotPasswordInit(): Observable<any> {
        return fromPromise(this.amplifyService.auth().forgotPassword(this.username));
    }

    /**
     * Forgot password confirm
     * @returns {Observable<any>}
     */
    public forgotPasswordConfirm(): Observable<any> {
        return fromPromise(this.amplifyService.auth().forgotPasswordSubmit(this.username, this.verificationCode, this.password));
    }

    /**
     * Resend the registration verification code
     * @returns {Observable<any>}
     */
    public resendSignUpCode(): Observable<any> {
        return fromPromise(this.amplifyService.auth().resendSignUp(this.username));
    }


    /**
     * Reset user parameters
     */
    public resetUserParameters(): void {
        this.username = null;
        this.email = null;
        this.password = null;
        this.verificationCode = null;
    }
}
