import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { LayoutComponent } from './layout/layout.component';
import { MatModule } from './shared/design/mat.module';
import { RoutingModule } from './app-routing.module';
import { FlexLayoutModule } from "@angular/flex-layout";





@NgModule({
    declarations: [
        AppComponent,
        LayoutComponent,
        
    ],
    imports: [
        BrowserModule,
        FlexLayoutModule,
        BrowserAnimationsModule,
        AmplifyAngularModule,
        MatModule,
        RoutingModule
    ],
    providers: [
        AmplifyService,
        AuthGuardService,
        AuthService,
        
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
