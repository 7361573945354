// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,

    amplifyConfig: {
        Auth: {
            identityPoolId: 'us-east-1:22708fe8-3220-48e4-aab0-730abef8c890',
            region: 'us-east-1',
            userPoolId: 'us-east-1_pkq6GdP0d',
            userPoolWebClientId: '5s73468n3u7jpfs7l59i2nd6es',
        },
        // API: {
        //     endpoints: [
        //         {
        //             name: "API NAME",
        //             endpoint: "https://API_ENDPOINT"
        //         }
        //     ]
        // }
    }
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
