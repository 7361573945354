<mat-sidenav-container class="sidenav-container" autosize>
  <mat-sidenav *ngIf="signedIn" #sidenav class="left-sidenav mat-elevation-z8 mat-blue-700-bg" mode="side" opened="true">

    <mat-nav-list class="p-0">
      <mat-toolbar class="p-0 mat-elevation-z8" color="pink">
        <div class="p-16" (click)="isExpanded = !isExpanded">
          <h2 *ngIf="isExpanded">
            <img class="logo" src="assets/images/logos/logo.png" />
          </h2>
          <div *ngIf="!isExpanded" fxLayout="row" fxLayoutAlign="center center">
            <img class="logo-icon" src="assets/images/logos/logoc.png" />
          </div>

        </div>
      </mat-toolbar>


      <mat-list-item routerLink="dashboard" routerLinkActive="active" class=" white-fg">
        <mat-icon mat-list-icon>dashboard</mat-icon>
        <p matLine *ngIf="isExpanded">Dashboard</p>
      </mat-list-item>
      <mat-list-item routerLink="users" routerLinkActive="active" class="white-fg">
        <mat-icon mat-list-icon>group</mat-icon>
        <p matLine *ngIf="isExpanded">Users</p>
      </mat-list-item>
    </mat-nav-list>
    <div class="menu-toggle-container">
      <mat-nav-list>
        <mat-list-item routerLink="profile" routerLinkActive="active" class="mt-24 white-fg">
          <mat-icon mat-list-icon>account_circle</mat-icon>
          <p matLine *ngIf="isExpanded">My Profile</p>
        </mat-list-item>
        <mat-list-item class="menu-toggle white-fg blue-900-bg" (click)="isExpanded = !isExpanded">
          <div *ngIf="!isExpanded">
            <mat-icon>chevron_right</mat-icon>
          </div>

          <div *ngIf="isExpanded" fxLayout="row" fxLayoutAlign="space-between center">

            <mat-icon>chevron_left</mat-icon>
            <small>
              Close
            </small>
          </div>



        </mat-list-item>
      </mat-nav-list>
    </div>


  </mat-sidenav>
  <!-- USER DROP DOWN MENU -->
  <div *ngIf="signedIn" fxLayout="row" fxLayoutAlign="end center" class="user-menu">
    <div class="avatar">
      {{user?.username.charAt(0).toUpperCase()}}
    </div>
    <div class="white-fg mr-40">
      {{user?.username}}
      <button mat-icon-button [matMenuTriggerFor]="appMenu">
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
    </div>
    <mat-menu class="mt-52 " #appMenu="matMenu">

      <button mat-menu-item routerLink="profile" routerLinkActive="active">
        <mat-icon>account_circle</mat-icon>
        Profile
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        Logout
      </button>

    </mat-menu>


  </div>
  <router-outlet></router-outlet>


</mat-sidenav-container>